import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { LOCATION_ORIGIN } from '../../../env';
import debug, { Raven } from '../../../debug';
import LocalStorage, { LS_KEY } from '../../../LocalStorage';
import {
    API_CLIENT_TO_BROWSER,
    API_BROWSER_TO_CLIENT, FILE_PROCESSING_TYPE, BLANK_PAGE, SCANNING_STATE, API_NESTED_IFRAME_TO_BROWSER
} from '../../../mirror/sharedConstants';
import { sendToClient, messageToOpener } from '../../../mirror/client/browserMessageBus';
import { apiCall } from '../../../API';
import copyText from '../../../copyText';
import { ERROR_VIEW_CODE } from '../../../constants';
import EventLogger from '../../../ClientEventLogger';
import { getScanStatus } from '../../../pollScanStatus';
import BrowserStore from '../../stores/BrowserStore';
import EnvStore from '../../../stores/EnvStore';
import HistoryStore from '../../stores/HistoryStore';
import ResearchStore from '../../stores/ResearchStore';
import Browser from './Browser';
import UDBrowser from '../../../Components/UD/UDBrowser';
import SaasBrowser from '../../../Components/SaasIsolation/SaasBrowser';
import DebugStore from '../../stores/DebugStore';
import { TroubleshootingModeScreen } from '../../../Components/TroubleshootingModeScreen';

EventLogger.extendContext({ ua: window.navigator.userAgent });

const makeFrameUrl = (url) => LOCATION_ORIGIN + url;
const makePageUrl = (
    url,
    frameUrl,
    clientPageId,
    onlyWebmailCheck = false,
    traceToken = null,
    clickId = null,
) => {
    if (frameUrl) {
        // frameUrl already has unique identifier of existing page id
        let finalUrl = makeFrameUrl(frameUrl);
        if (traceToken) {
            finalUrl += `&traceToken=${traceToken}`;
        }
        if (clickId) {
            finalUrl += `&clickId=${clickId}`;
        }
        return finalUrl;
    }
    if (url && clientPageId) {
        let query = `cid=${clientPageId}`;
        if (onlyWebmailCheck) {
            query += '&userinput=1';
        }
        if (traceToken) {
            query += `&traceToken=${traceToken}`;
        }
        if (clickId) {
            query += `&clickId=${clickId}`;
        }
        query += `&url=${encodeURIComponent(url)}`;
        return makeFrameUrl(`/page?${query}`);
    }
    return '';
};

const closePageApi = apiCall('/page/close', { isUserAction: false });
const deleteRouteCookie = apiCall('/page/deleteRouteCookie', { isUserAction: false });
function removeRouteCookie() {
    debug.info('going to delete route cookie');
    deleteRouteCookie().catch(debug.warn);
}

const DEFAULT_TITLE = document.title;


// allow reloads on recently loaded pages without "onlyWebmail" check
function isWhitelistedRecentUrl(newUrl) {
    if (newUrl) {
        const recentUrls = LocalStorage.get(LS_KEY.recentlyLoadedUrls, []);
        return recentUrls.indexOf(newUrl) !== -1;
    }
}

function registerRecentUrl(newUrl) {
    if (newUrl) {
        const recentUrls = LocalStorage.get(LS_KEY.recentlyLoadedUrls, []);
        recentUrls.push(newUrl);
        LocalStorage.set(LS_KEY.recentlyLoadedUrls, recentUrls.slice(0, 25));
    }
}

class BrowserController extends Component {
    static propTypes = {
        browser: BrowserStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
        history: HistoryStore.PropType.isRequired,
        debug: DebugStore.PropTypes.isRequired,
        research: ResearchStore.PropType.isRequired,

        t: PropTypes.func.isRequired,
    };

    getNewState(props) {
        const clientPageId = props.history.location.state.clientPageId;
        const isHomePage = !clientPageId;
        const isLoading = !isHomePage;
        const { url, frameUrl } = props.history.location.query;

        if (props.env.isWebmailDefense && url && !frameUrl) {
            props.browser.webmail.registerVisited(url);
        }
        if (isLoading) {
            EventLogger.extendContext({ location: url, clientPageId, pageId: undefined });
            EventLogger.mark(EventLogger.EVENT_BROWSER_PAGE_LOAD);
        }

        try {
            if (
                props.browser.frameEl
                && props.browser.frameEl.contentWindow
                && props.browser.frameEl.contentWindow._onUnload
            ) {
                props.browser.frameEl.contentWindow._onUnload();
            }
        } catch (e) {
            debug.error(e);
        }

        let iframeSrc = '';
        if (props.env.isWebmailDefense) {
            iframeSrc = makePageUrl(url, frameUrl, clientPageId, !isWhitelistedRecentUrl(url));
        } else if (props.env.isUrlIsolation || props.env.isSaasIsolation) {
            iframeSrc = makePageUrl(url, frameUrl, clientPageId, false,
                props.history.traceToken, props.history.clickId);
        } else {
            // default
            iframeSrc = makePageUrl(url, frameUrl, clientPageId);
        }

        props.debug.onPageLoadStarted({ url, clientPageId, iframeSrc });

        let blankPageParentURL = '';
        if (!!this.props.history.location.query.url
            && this.props.history.location.query.url.startsWith('POPUP WINDOW')) {
            blankPageParentURL = this.props.history.location.query.url;
        }

        clearTimeout(this.loadSafeguardTM);

        return {
            iframeSrc,
            iframeOpacity: this?.state?.iframeOpacity === 1 ? 1 : 0,
            pageAttributes: null,
            serverInfo: null,
            isLoading,
            loadingIsAborted: false,
            blankPageParentURL,
        };
    }

    constructor(...args) {
        super(...args);

        this.state = this.getNewState(this.props);
        this.updateTroubleshootingInstructionsScreen();
        this.props.browser.setUrlInputValue(this.props.history.location.query.url);
        this.props.browser.inactivity.stopCountdown();
        this.props.browser.copyFromBrowserState(this.state);
        this.props.browser.sandboxClickId = this.props.history.clickId;
        if (this.props.env.isUrlIsolation) {
            // for isolation exit
            this.props.browser.setOriginalUrl(this.props.history.location.query.url, this.props.history.clickId);
        }

        this.currentClientPageId = '';
        this.prevClientPageId = '';
        this.lastClosedLoadingPage = '';
        this.currentLocation = this.props.history.location;
    }

    componentWillReceiveProps(nextProps) {
        this.props.browser.setUrlInputValue(nextProps.history.location.query.url);

        // this is required cos mobx does not serialize to a new object,
        // so nextProps.store.field === this.props.store.field, always
        const currentLocation = this.currentLocation;
        const nextLocation = nextProps.history.location;

        this.currentLocation = nextLocation;
        this.prevLocation = currentLocation;

        if (!currentLocation || !nextLocation) {
            return;
        }

        if (currentLocation.state.clientPageId === nextLocation.state.clientPageId) {
            if (nextLocation !== currentLocation && nextLocation.shouldNotifyServerBrowser) {
                sendToClient(this.props.browser.frameEl, API_BROWSER_TO_CLIENT.historyChange, nextLocation);
            }
            return;
        }

        if (this.props.browser.socketState.status === 'error' || this.props.browser.socketState.status === 'closed') {
            window.top.location.reload();
            return;
        }

        const newState = this.getNewState(nextProps);
        this.setState(newState, () => {
            debug.log('BrowserController.setState', newState, currentLocation, nextLocation);
            this.props.browser.copyFromBrowserState(newState);
            this.props.browser.resetPageState();
            this.updateTroubleshootingInstructionsScreen();
        });
    }

    componentDidUpdate() {
        // home page
        if (!this.props.history.location.state.clientPageId) {
            document.title = DEFAULT_TITLE;
        }

        if (this.currentLocation && this.prevLocation) {
            const currentClientPageId = this.currentLocation.state.clientPageId;
            const prevClientPageId = this.prevLocation.state.clientPageId;
            if (currentClientPageId !== prevClientPageId) {
                this.closeLoadingPage(prevClientPageId);
            }
        }
    }

    updateTroubleshootingInstructionsScreen() {
        const showTroubleshootingInstructionsScreen = window.location.pathname.indexOf('/troubleshooting') >= 0;
        this.props.browser.setTroubleshootingInstructionsScreen(showTroubleshootingInstructionsScreen);
    }

    componentDidMount() {
        window.addEventListener('message', this.onMessageFromFrame, false);
        window.addEventListener('beforeunload', () => {
            this.isWindowUnloading = true;
            if (this.state.isLoading) {
                const currentClientPageId = this.props.history.location.state.clientPageId;

                this.closeLoadingPage(currentClientPageId);
            }
        });
        window.addEventListener('unload', () => {
            if (this.state.isLoading) {
                const currentClientPageId = this.props.history.location.state.clientPageId;

                this.closeLoadingPage(currentClientPageId);
            }
        });

        if (this.props.browser.homePageNotWelcome) {
            this.props.browser.setHomePageTabId(0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onMessageFromFrame, false);
    }

    closeLoadingPage(clientPageId) {
        if (clientPageId && this.lastClosedLoadingPage !== clientPageId) {
            this.lastClosedLoadingPage = clientPageId;
            this.props.browser.fileManagerStore.cancelAllUploads();
            closePageApi({ clientPageId }).catch(debug.warn);
        }
    }

    onSubmit = (newRequestString) => {
        if (newRequestString) {
            this.props.history.openPage(newRequestString);
        }
    };

    lastPageId;

    onLoad = (
        {
            type = 'success',
            pageId = '',
            browserId = '',
            serverBrowserVersion,
            errorViewCode,
            finalUrl,
            customMetadata,
            ...additional
        }) => {
        clearTimeout(this.loadSafeguardTM);
        if (this.state.isLoading) {
            this.props.browser.pageOpenedTs = Date.now();
            this.lastPageId = pageId;

            const update = {};
            if (pageId) {
                update.pageId = pageId;
            }
            if (finalUrl) {
                update.location = finalUrl;
            }
            EventLogger.extendContext(update);
            if (browserId) {
                EventLogger.extendContext({ browserId });
            }
            if (customMetadata) {
                EventLogger.extendContext({ customMetadata });
            }

            EventLogger.measureAndSend(EventLogger.EVENT_BROWSER_PAGE_LOAD,
                { type, errorViewCode, ...additional });
            EventLogger.logClientConfigEvent();
            this.setState({ isLoading: false }, () => this.props.browser.copyFromBrowserState(this.state));
            this.props.debug.onPageLoadFinish({
                pageId,
                browserId,
                loadType: type,
                serverBrowserVersion,
                additionalLoadInfo: additional,
            });
            this.props.browser.contentFilteringBlockPage.setValue(
                errorViewCode === ERROR_VIEW_CODE.BLOCKED_SITE ||
                errorViewCode === ERROR_VIEW_CODE.BLOCKED_UNSAFE,
            );

            if (type === 'error' && [
                ERROR_VIEW_CODE.BLOCKED_SITE,
                ERROR_VIEW_CODE.BLOCKED_UNSAFE,
                ERROR_VIEW_CODE.NEVER_ISOLATE,
                ERROR_VIEW_CODE.NEVER_ISOLATE_INSTANT,
                ERROR_VIEW_CODE.RESERVING_BROWSER,
            ].indexOf(errorViewCode) === -1) {
                if (this.props.env.isUrlIsolation) {
                    this.props.browser.waitSandboxTimeout();
                }
                if (this.props.browser.useRedesignedPolicy) {
                    getScanStatus(`errorPageDetails-${pageId}`).then((result) => {
                        if (this.lastPageId === pageId) {
                            this.props.browser.setExitIso(result);
                        }
                    });
                }
            }
        }
    };

    loadSafeguardTM = 0;
    onIframeLoad = (event) => {
        if (!event.target || !event.target.isConnected || event.target !== this.props.browser.frameEl) {
            debug.log('onIframeLoad', 'page changed already');
            return;
        }

        if (!this.props.browser.frameEl.contentWindow) {
            // may happen if user lost internet connection during page load
            this.onLoad({
                type: 'loadWithoutContentWindow',
            });
        } else {
            let isLoaded = false;
            let err = null;
            try {
                isLoaded = this.props.browser.frameEl.contentWindow.LOADED;
            } catch (e) {
                err = e;
            }
            if (err) {
                // may happen if user loaded different page, e.g. antivirus/firewall redirected
                this.onLoad({
                    type: 'loadWithDifferentOrigin',
                });
            } else if (!isLoaded) {
                // may happen if user lost internet connection during page load
                this.onLoad({
                    type: 'loadWithoutResponse',
                });
            } else {
                clearTimeout(this.loadSafeguardTM);
                this.loadSafeguardTM = setTimeout(() => {
                    this.onLoad({
                        type: 'loadUnknownState',
                    });
                }, 15000);
            }
        }
    };

    showHomePage = (isReplace) => {
        if (isReplace) {
            window.location.replace('/browser');
        } else {
            window.location.assign('/browser');
        }
    };

    // TODO: move handlers into fields e.g. [API_CLIENT_TO_BROWSER.showOnlyWebmail]: () => {}
    onMessageFromFrame = (event) => {
        let json = null;
        try {
            json = JSON.parse(event.data);
        } catch (e) {
            return;
        }

        if (this.isWindowUnloading) {
            return;
        }

        if (event.source === window) {
            // disable reaction on third party extensions content scripts messages
            return;
        }

        if ((event.origin || event.originalEvent.origin) !== LOCATION_ORIGIN) {
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.closeMe) {
            if (!event.source || !event.source.top) {
                return;
            }
            const childWindow = event.source.top;

            try {
                childWindow.close();
            } catch (e) { /* whatever */ }

            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.showIframe) {
            if (json.args && json.args.pageLoaded) {
                if (this.state.iframeOpacity && !json.args.isIframePainted) {
                    this.forceUpdate();
                    return;
                }
            }
            this.setState({ iframeOpacity: 1 });
        }

        if (json.method === API_CLIENT_TO_BROWSER.setViewport) {
            this.props.browser.setViewport(json.args);
            return;
        }

        const { browser } = this.props;

        if (json.method === API_CLIENT_TO_BROWSER.loadErrorClosedOnServer
            || json.method === API_CLIENT_TO_BROWSER.closeSelf
        ) {
            messageToOpener(API_CLIENT_TO_BROWSER.closeMe);

            try {
                window.close();
                window.open('', '_self', '').close();
            } catch (e) { /* whatever */ }

            setTimeout(() => {
                // showing home page if failed to close
                this.showHomePage(json.args && json.args.replace);
            }, 1000);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.showHomePage) {
            this.showHomePage(json.args && json.args.replace);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.showOnlyWebmail) {
            this.props.browser.notWebmailError.show();
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.DEFAULT_LEFT);
            this.setState({
                iframeSrc: '',
            }, () => this.props.browser.copyFromBrowserState(this.state));
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.firstRenderFinished) {
            this.onLoad(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.openWindow) {
            this.props.browser.popupData = json.args;
            this.props.browser.showQuickNotification(BrowserStore.NOTIFICATION.POPUP_DETECTED, 60 * 1000);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.notifySessionTimeout) {
            this.props.browser.inactivity.startCountdown(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.notifySessionExtended) {
            this.props.browser.inactivity.stopCountdown();
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.setPageAttributes) {
            if (json.args.sessionId) {
                json.args.sessionId = json.args.sessionId.slice(0, 10);
            }

            EventLogger.extendContext({
                pageId: json.args.pageId,
                location: json.args.location,
            });

            this.props.browser.fileManagerStore.checkToClearAllBlockedEvents(json.args.browserId);
            this.props.browser.updateFromPageAttributes(json.args);
            const newState = { pageAttributes: json.args };
            if (json.args.error !== undefined) {
                newState.iframeOpacity = 1;
            }
            this.setState(newState);
            return;
        }
        if (json.method === API_NESTED_IFRAME_TO_BROWSER.nested_iframe_paint_event) {
            sendToClient(this.props.browser.frameEl, API_BROWSER_TO_CLIENT.nestedIframePaintEvent, json.data);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.serverInfo) {
            this.setState({ serverInfo: json.args });
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.fileProcessing) {
            try {
                if (json.args.type === FILE_PROCESSING_TYPE.UPLOAD && !json.args.url) {
                    json.args.url = this.props.history.location.query.url;
                }
                EventLogger.postFileProcessingEvent({ ...json.args, clickId: this.props.history.clickId });
            } catch (err) {
                debug.error(err);
            }
            this.props.browser.showAndSaveFileProcessNotification(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.openUploadPopup) {
            this.props.browser.uploadPopup.setTrue();
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.socketError) {
            browser.inactivity.stopCountdown();
            this.props.debug.onWebsocketError();
            this.props.browser.updateSocketState({
                status: 'error',
                code: -1,
                reason: '',
            });
            removeRouteCookie();
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.socketClosedByServer) {
            browser.inactivity.stopCountdown();
            this.props.debug.onWebsocketDisconnected();
            this.props.browser.updateSocketState({
                status: 'closed',
                code: json.args.code,
                reason: json.args.reason,
            });
            removeRouteCookie();
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.socketCloseWithReconnect) {
            browser.inactivity.stopCountdown();
            this.props.debug.onWebsocketDisconnected();
            this.props.browser.updateSocketState({
                status: 'reconnecting',
                code: json.args.code,
                reason: '',
                reconnectCounter: this.props.browser.socketState.reconnectCounter + 1,
            });
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.initialConnectIsDone) {
            this.props.debug.onWebsocketConnected();
            browser.inactivity.stopCountdown();
            this.props.browser.updateSocketState({
                status: 'opened',
                code: 0,
                reason: '',
                initialConnectIsDone: true,
            });
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.disableDownload) {
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.DOWNLOADS_DISABLED);
            if (json.args && json.args.showHomePage) {
                if (this.props.env.isUrlIsolation) {
                    this.props.browser.directDownloadPage.open();
                } else {
                    this.setState({ iframeSrc: '' }, () => this.props.browser.copyFromBrowserState(this.state));
                }
            }
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.pageInitError) {
            browser.showErrorPage('pageInit - ' + (json?.args?.err), json?.args?.location);
            this.onLoad({ type: 'loadErrorPage' });
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.socketReconnect) {
            this.props.debug.onWebsocketConnected();
            this.props.browser.updateSocketState({
                status: 'opened',
                code: 0,
                reason: '',
                reconnectCounterTotal: this.props.browser.socketState.reconnectCounterTotal + 1,
                reconnectCounter: 0,
            });
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.appLauncher) {
            this.props.browser.showQuickNotification(BrowserStore.NOTIFICATION.LOCAL_APPLICATION_BLOCKED);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.windowScroll) {
            if (browser.topBarNeedsMinimization.isTrue && browser.isTopBarMinimizable()) {
                browser.topBarNeedsMinimization.setFalse();
            }
            this.props.browser.setPageOffset(json.args.pageYOffset);
        }

        if (json.method === API_CLIENT_TO_BROWSER.showDlp) {
            if (browser.isHarScanInputRestrictions()) {
                // input restrictions notification will be closed after scan finished
                browser.showNotification(this.props.browser.inputRestrictions);
            } else {
                browser.showQuickNotification(this.props.browser.inputRestrictions);
            }
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.socketStatus) {
            const { status } = this.props.browser.socketState;
            if (json.args.ok && status !== 'opened') {
                this.props.browser.updateSocketState({
                    status: 'opened',
                    code: 0,
                    reason: '',
                    reconnectCounter: 0,
                });
            } else if (!json.args.ok && status === 'opened') {
                this.props.browser.updateSocketState({
                    status: 'reconnecting',
                    reason: '',
                });
            }
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.history) {
            const { browserTabId, currentIndex, entries } = json.args;
            const currentEntry = entries[currentIndex];
            if (this.props.env.isWebmailDefense && currentEntry.url && !currentEntry.frameUrl) {
                registerRecentUrl(currentEntry.url);
            }
            this.props.history.updateHistory(browserTabId, currentIndex, entries);
            const EntryUrl = currentEntry.url === '' && this.state.pageAttributes?.location === BLANK_PAGE
                ? this.state.blankPageParentURL : currentEntry.url;
            this.props.browser.setUrlInputValue(EntryUrl);

            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.requestFullscreen) {
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.exitFullscreen) {
            this.props.browser.closeNotification(BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.requestBasicAuthorization) {
            const { challengerUrl } = json.args;
            this.props.browser.basicAuth.challengerUrl = challengerUrl;
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.requestSetClipboard) {
            if (!copyText(json.args.text)) {
                // failed to copy without user interaction
                this.props.browser.requestSetClipboardText = json.args.text;
                this.props.browser.showNotification(BrowserStore.NOTIFICATION.CLIPBOARD_PERMISSION);
            } else {
                // copied successfully, close previous requestSetClipboardModal
                this.props.browser.closeNotification(BrowserStore.NOTIFICATION.CLIPBOARD_PERMISSION);
            }
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.requestOpenMailClient) {
            this.props.browser.requestedMailtoUrl = json.args.mailtoUrl;
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.EMAIL_PERMISSION);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.setTitle) {
            const { title } = json.args;
            document.title = title || DEFAULT_TITLE;
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.setDLP) {
            this.props.browser.setDLP(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.setDownloadUploadDLP) {
            this.props.browser.setDownloadUploadDLP(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.sandboxScanResult) {
            this.props.browser.onSandboxScanResult(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.tiScanResult) {
            this.props.browser.onTiScanResult(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.tsaScanResult) {
            const { disposition } = json.args;
            if (disposition === 'BLOCK') {
                EventLogger.sendEventTSAScanBlock({ ...json.args, clickId: this.props.history.clickId });
            } else if (disposition === 'ERROR') {
                EventLogger.sendEventTSAScanError({ ...json.args, clickId: this.props.history.clickId });
            }
            this.props.browser.onTsaScanResult(disposition);

            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.fileScanResult) {
            this.props.browser.onFileScanResult(...json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.iframeReputationScan) {
            if (json.args.status === SCANNING_STATE.BLOCKED || json.args.status === SCANNING_STATE.ALLOWED) {
                EventLogger.sendEventSubFrameReputation(Object.assign(json.args, {
                    clickId: this.props.history.clickId || ''
                }));
            }
            this.props.browser.onSubFrameScanResult(json.args);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.neverIsolate) {
            this.props.browser.showNotification(BrowserStore.NOTIFICATION.NEVER_ISOLATE);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.redirectToBlockPage) {
            if (this.props.browser.blockPage) {
                window.location.replace(`${this.props.browser.blockPage}?cause=resource_allocation_failure`);
            }
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.deleteRouteCookie) {
            removeRouteCookie();
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.iframeBlocked) {
            EventLogger.sendRuleEngineBlockEvent(json.args, this.props.history.clickId);
            return;
        }

        if (json.method === API_CLIENT_TO_BROWSER.setExitIso) {
            this.props.browser.setExitIso(json.args);
        }

        if (json.method === API_CLIENT_TO_BROWSER.setRole) {
            // Kick out people who have research enabled, but have had the research setting availability taken away
            const researchMode = this.props.research.researchMode;
            if (researchMode.getValue() && !json.args.allowResearchMode) {
                researchMode.setValue(false);
                researchMode.commit();
            }
            this.props.browser.setRole(json.args);
        }

        if (json.method === API_CLIENT_TO_BROWSER.saveActivityLoggerEvent) {
            this.props.browser.activityLoggerEvent = json.args;
        }
    };

    stopPageRequest = () => {
        if (!this.state.isLoading) {
            return;
        }

        const currentClientPageId = this.props.history.location.state.clientPageId;

        this.closeLoadingPage(currentClientPageId);

        this.setState({
            isLoading: false,
            loadingIsAborted: true,
        }, () => this.props.browser.copyFromBrowserState(this.state));
        this.props.browser.uploadPopup.setFalse();
    };

    onRefresh = (refreshTopPage = false) => {
        if (refreshTopPage) {
            window.top.location.reload();
            return;
        }

        if (this.props.browser.socketState
            && (this.props.browser.socketState.status === 'error'
                || this.props.browser.socketState.status === 'closed')
        ) {
            window.top.location.reload();
            return;
        }

        this.stopPageRequest();
        this.props.history.reloadPage();
    };

    getLatestErrors() {
        const browserError = Raven.lastException() ? Raven.lastException().toString() : 'No detected errors';
        let clientError = 'No detected errors';

        try {
            const foreignRaven = this.props.browser.frameEl.contentWindow.Raven;
            if (foreignRaven && foreignRaven.lastException()) {
                clientError = foreignRaven.lastException().toString();
            }
        } catch (e) {
            clientError = 'No access to errors of client';
        }

        return { browserError, clientError };
    }

    getStateForTroubleshooting() {
        return {
            state: {
                pageAttributes: this.state.pageAttributes,
                isLoading: this.state.isLoading,
                loadingIsAborted: this.state.loadingIsAborted,
                serverInfo: this.state.serverInfo,
                socketState: this.props.browser.socketState,
                iframeSrc: this.state.iframeSrc,
                dateOfData: new Date().toString(),
            },
            errors: this.getLatestErrors(),
            userAgent: navigator.userAgent,
            mirrorFeatureFlags: {
                client: this.props.env.mirrorFeatureFlags,
                server: this.state.pageAttributes?.mirrorFeatureFlags || [],
            },
        };
    }

    renderBrowser() {
        const isHomePage = !this.props.history.location.state.clientPageId;
        const showLoadingAnimation = this.state.isLoading || (!isHomePage && this.state.iframeOpacity === 0);
        if (this.props.env.isUrlIsolation) {
            return <UDBrowser
                iframeSrc={this.state.iframeSrc}
                iframeOpacity={this.state.iframeOpacity}
                isLoading={showLoadingAnimation}
                onLoad={this.onIframeLoad}
                onRefresh={this.onRefresh}
            />;
        }

        if (this.props.env.isSaasIsolation) {
            return <SaasBrowser
                iframeSrc={this.state.iframeSrc}
                iframeOpacity={this.state.iframeOpacity}
                isLoading={showLoadingAnimation}
                onLoad={this.onIframeLoad}
                onRefresh={this.onRefresh}
            />;
        }

        return <Browser
            iframeSrc={this.state.iframeSrc}
            isLoading={showLoadingAnimation}
            iframeOpacity={this.state.iframeOpacity}
            loadingIsAborted={this.state.loadingIsAborted}

            onRefresh={this.onRefresh}
            onSubmit={this.onSubmit}
            onLoad={this.onIframeLoad}
        />;
    }

    render() {
        this.props.browser.setDiagnostics(this.getStateForTroubleshooting());

        const browser = this.renderBrowser();

        if (this.props.browser.troubleshootingMode) {
            return <TroubleshootingModeScreen>{browser}</TroubleshootingModeScreen>;
        }

        return browser;
    }
}

export default translate()(inject(
    'env',
    'browser',
    'history',
    'debug',
    'research',
)(observer(BrowserController)));
