import { action, extendObservable, reaction } from 'mobx';
import PropTypes from 'prop-types';
import debug, { DEBUG } from '../../debug';
import LocalStorage, { LS_KEY } from '../../LocalStorage';
import makeElementId from '../../makeElementId';
import { sendToClient } from '../../mirror/client/browserMessageBus';
import { API_BROWSER_TO_CLIENT, CLIENT_WINDOW_METHODS, SCANNING_STATE } from '../../mirror/sharedConstants';
import { apiCall } from '../../API';
import pollScanStatus from '../../pollScanStatus';
import { getDefaultBrowserBarOptions } from '../../stores/BrowserBarOptions';
import syncStoreWithLocalStorage from '../../stores/helpers/syncStoreWithLocalStorage';
import InactivityStore from '../../stores/InactivityStore';
import WebmailStore from '../../stores/WebmailProvidersStore';
import FlagStore from '../../stores/FlagStore';
import { copyTextOnClick } from '../../copyText';
import { IS_DEV, IS_MOBILE } from '../../env';
import Screen from '../../Screen';
import SendFeedbackStore from '../../stores/SendFeedbackStore';
import BasicAuthStore from '../../stores/BasicAuthStore';
import FileManagerStore from '../../stores/FileManagerStore';
import AccessCodesSettingsStore from '../../stores/BrowserPolicies/AccessCodesSettingsStore';
import AsyncActionStore from '../../stores/AsyncActionStore';
import EventLogger from '../../ClientEventLogger';
import { registerUserGesture } from '../botDetector';
import { Mod, Key } from '../../mirror/userInput';

const logger = debug.create('BrowserStore', true);


function setOriginalUrlByClickId(url, clickId) {
    return LocalStorage.setRaw('original_url_' + clickId, url);
}

function focusEl(el) {
    if (el && el.focus) {
        window.focus();
        el.focus();
    }
}

function canShowBlockPageInTheIframe(url = '') {
    return IS_DEV // no CSP on DEV
        || url.startsWith('https://urldefense.com/')
        || url.startsWith('https://urldefense.proofpoint.com/');
}

// TODO: extra measures to prevent infinite loop
let diagnostics = {};

const defaultSocketState = {
    status: 'wait',
    code: 0,
    reason: '',
    initialConnectIsDone: false,
    reconnectCounterTotal: 0,
    reconnectCounter: 0,
};

const SCAN_STATUS = {
    NONE: 'NONE',
    SCANNING: 'SCANNING',
    ERROR: 'ERROR',
    CLEAN: 'CLEAN',
    DISABLED: 'DISABLED', // TSA turned off
    SUSPECT: 'SUSPECT',
    BLOCK: 'BLOCK',
};

export default class BrowserStore {
    static NOTIFICATION = {
        DEFAULT_RIGHT: 'DEFAULT_RIGHT',
        DEFAULT_LEFT: 'DEFAULT_LEFT',
        INPUT_DISABLED: 'INPUT_DISABLED',
        PASTE_DISABLED: 'PASTE_DISABLED',
        PASTE_TRUNCATED: 'PASTE_TRUNCATED',
        CLIPBOARD_PERMISSION: 'CLIPBOARD_PERMISSION',
        FULL_SCREEN_PERMISSION: 'FULL_SCREEN_PERMISSION',
        EMAIL_PERMISSION: 'EMAIL_PERMISSION',
        PAUSING_SESSION: 'PAUSING_SESSION',
        PAUSING_SESSION_CANCELLED: 'PAUSING_SESSION_CANCELLED',
        SESSION_PAUSED: 'SESSION_PAUSED',
        ANOTHER_TAB_THREAT_DETECTED: 'ANOTHER_TAB_THREAT_DETECTED',
        DISCONNECTED: 'DISCONNECTED',
        TRYING_TO_CONNECT: 'TRYING_TO_CONNECT',
        CONNECTION_ESTABLISHED: 'CONNECTION_ESTABLISHED',
        POPUP_DETECTED: 'POPUP_DETECTED',
        CUSTOMER_MESSAGE: 'CUSTOMER_MESSAGE',
        LOCAL_APPLICATION_BLOCKED: 'LOCAL_APPLICATION_BLOCKED',
        CONFIRM_EXIT_ISOLATION: 'CONFIRM_EXIT_ISOLATION',
        NEW_CONFIRM_EXIT_ISOLATION: 'NEW_CONFIRM_EXIT_ISOLATION',
        ENTER_ISOLATION_MESSAGE: 'ENTER_ISOLATION_MESSAGE',
        PHISHING: 'PHISHING',
        NEVER_ISOLATE: 'NEVER_ISOLATE',
        REQUEST_BASIC_AUTHORIZATION: 'REQUEST_BASIC_AUTHORIZATION',
        FILE_PROCESSING: FileManagerStore.NOTIFICATION.FILE_PROCESSING,
        DOWNLOADS_DISABLED: 'DOWNLOADS_DISABLED',
        UPLOADS_DISABLED: 'UPLOADS_DISABLED',
        HUMAN_CHECK: 'HUMAN_CHECK',
        COPY_URL_TO_EXIT: 'COPY_URL_TO_EXIT'
    };

    static INPUT_RESTRICTION = {
        INPUT_DISABLED: 'INPUT_DISABLED',
        PASTE_DISABLED: 'PASTE_DISABLED',
        PASTE_TRUNCATED: 'PASTE_TRUNCATED',
        NONE: 'NONE',
    };

    static PAGE_STATUS = {
        NONE: 'NONE',
        SECURE_SITE: 'SECURE_SITE',
        NOT_SECURE_SITE: 'NOT_SECURE_SITE',
        MALICIOUS_SITE: 'MALICIOUS_SITE',
        PHISHING_SITE: 'PHISHING_SITE',
        EXPIRED_CERTIFICATE: 'EXPIRED_CERTIFICATE',
        NOT_WEB_MAIL: 'NOT_WEB_MAIL',
        SCAN_ERROR: 'SCAN_ERROR',
        ERROR_PAGE: 'ERROR_PAGE',
        THREAT_SCAN: 'THREAT_SCAN',
    };

    static SCAN_STATUS = SCAN_STATUS;

    static PropType = PropTypes.shape({
        checkUrlScoreStatus: AsyncActionStore.PropType.isRequired,

        printEnabled: PropTypes.bool.isRequired,
        clearCookiesEnabled: PropTypes.bool.isRequired,
        feedbackEnabled: PropTypes.bool.isRequired,
        faqEnabled: PropTypes.bool.isRequired,
        adBlockSwitchEnabled: PropTypes.bool.isRequired,
        blockAdsByDefault: PropTypes.bool.isRequired,
        settingsResearchMode: PropTypes.bool.isRequired,
        invitesEnabled: PropTypes.bool.isRequired,
        homePageNotWelcome: PropTypes.bool.isRequired,
        isAutoProvisioned: PropTypes.bool.isRequired,
        customMaliciousThreatBlockPage: PropTypes.bool.isRequired,
        displayExitWarning: PropTypes.bool.isRequired,
        exitAutomatically: PropTypes.bool.isRequired,
        exitWarningText: PropTypes.any,
        enterIso: PropTypes.any,

        useRedesignedPolicy: PropTypes.bool,

        shouldFocusInputEl: PropTypes.bool.isRequired,
        urlInputValue: PropTypes.string.isRequired,
        urlInputFocus: FlagStore.PropType,

        topBarMenu: FlagStore.PropType,
        topBarUnpinned: FlagStore.PropType,
        isTopBarMinimizable: PropTypes.func.isRequired,
        topBarNeedsMinimization: FlagStore.PropType,
        isTopBarMinificationAllowed: PropTypes.func.isRequired,
        settingsModal: FlagStore.PropType,
        adBlockModal: FlagStore.PropType,
        adBlock: FlagStore.PropType,
        errorPage: FlagStore.PropType,

        hasPage: PropTypes.bool.isRequired,
        isFocused: PropTypes.func.isRequired,
        getOriginalUrlByClickId: PropTypes.func.isRequired,

        reserveBrowser: PropTypes.func.isRequired,

        setUrlInputEl: PropTypes.func.isRequired,
        focusUrlInput: PropTypes.func.isRequired,
        submitUrlInput: PropTypes.func.isRequired,
        setUrlInputValue: PropTypes.func.isRequired,
        updateUrlInputValue: PropTypes.func.isRequired,
        onUrlInputMouseUp: PropTypes.func.isRequired,
        setViewport: PropTypes.func.isRequired,
        getVisualState: PropTypes.func.isRequired,

        setFrameEl: PropTypes.func.isRequired,
        printPage: PropTypes.func.isRequired,

        setDiagnostics: PropTypes.func.isRequired,
        getDiagnostics: PropTypes.func.isRequired,

        webmail: WebmailStore.PropType.isRequired,
        basicAuth: BasicAuthStore.PropType.isRequired,

        openFullscreen: PropTypes.func.isRequired,
        exitFullscreen: PropTypes.func.isRequired,

        allowSetClipboard: PropTypes.func.isRequired,

        sendFeedbackStore: SendFeedbackStore.PropType.isRequired,
        troubleshootingMode: PropTypes.bool.isRequired,
        troubleshootingCaseId: PropTypes.string.isRequired,
        troubleshootingInstructionsScreen: PropTypes.bool.isRequired,
        troubleshootingInstructionsModal: FlagStore.PropType.isRequired,


        updateSocketState: PropTypes.func.isRequired,
        socketState: PropTypes.shape({
            reconnectCounter: PropTypes.number.isRequired,
            reconnectCounterTotal: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            reason: PropTypes.string,
            code: PropTypes.number.isRequired,
            initialConnectIsDone: PropTypes.bool.isRequired,
        }).isRequired,

        faqModal: FlagStore.PropType.isRequired,
        faqSection: PropTypes.number,

        setDLP: PropTypes.func.isRequired,
        inputRestrictions: PropTypes.oneOf(Object.values(BrowserStore.INPUT_RESTRICTION)),
        inputRestrictionsDetails: FlagStore.PropType.isRequired,
        inputRestrictionsReason: PropTypes.string, // TODO: make it enum
        isHarScanInputRestrictions: PropTypes.func.isRequired,
        downloadsDisabledDetails: FlagStore.PropType.isRequired,
        uploadsDisabledDetails: FlagStore.PropType.isRequired,
        isUploadDisabled: PropTypes.bool.isRequired,
        isDownloadDisabled: PropTypes.bool.isRequired,
        fileManagerStore: FileManagerStore.PropType.isRequired,
        uploadPopup: FlagStore.PropType.isRequired,
        activePolicy: PropTypes.string.isRequired,

        getStores: PropTypes.func.isRequired,
        subFrameMaliciousUrl: PropTypes.string,
    });

    constructor(data = {}, useLocalStorage = true, stores = {}) {
        this.getStores = () => stores;
        this.useLocalStorage = useLocalStorage;

        document.addEventListener('keydown', (e) => {
            const mod = Mod.fromEvent(e);
            if (e.keyCode === Key.P && (mod === Mod.CTRL || mod === Mod.META)) {
                e.preventDefault();
                this.printPage();
            }
        }, true);
        if (this.useLocalStorage && data.blockPage) {
            LocalStorage.set(LS_KEY.udBlockPage, data.blockPage);
        }
        const sync = this.useLocalStorage ? syncStoreWithLocalStorage : (x) => x;

        extendObservable(this, /** @class BrowserStore */{
            // APIs
            reserveBrowserApi: apiCall('/browser/reserve', { isUserAction: false }),
            getActivationToken: apiCall('/activation-link', { isUserAction: false }),
            checkUrlScore: apiCall('/ti/check', { isUserAction: false }),
            errorPageApi: apiCall('/browser/error', { isUserAction: false }),
            exitIsolationStatus: new AsyncActionStore(),
            // sandbox
            sandboxTimeout: 0, // backend provided
            checkUrlScoreStatus: new AsyncActionStore(),
            sandboxClickId: '',
            checkUrlScoreTm: 0,
            pageOpenedTs: 0,

            // display options
            printEnabled: true,
            clearCookiesEnabled: true,
            feedbackEnabled: true,
            faqEnabled: true,
            adBlockSwitchEnabled: true,
            blockAdsByDefault: true,
            settingsResearchMode: false,
            invitesEnabled: false,
            homePageNotWelcome: false,
            isAutoProvisioned: false,
            customMaliciousThreatBlockPage: false,
            activePolicy: 'Default Policy',
            displayExitWarning: true,
            exitWarningText: null,
            enterIso: null,
            useRedesignedPolicy: false,
            allowAccessCodes: true,
            roleId: null,
            roleName: 'Default Browsing Role',

            // custom branding
            browserBarOptions: getDefaultBrowserBarOptions(),
            updateBrowserBarOptions: action((obj) => {
                Object.keys(this.browserBarOptions).forEach((key) => {
                    if (obj[key] !== undefined) {
                        this.browserBarOptions[key] = obj[key];
                    }
                });
            }),
            customBrandingInfoDropdown: new FlagStore(false),
            isConsolePreview: false, // for console preview specificity

            frameEl: null,
            urlInputEl: null,
            screen: null,
            viewport: null,

            originalQuery: {},
            shouldFocusInputEl: false,
            urlInputValue: '',
            urlInputFocus: new FlagStore(false),

            basicAuth: new BasicAuthStore(),

            notification: null,
            showNotification: action((type) => {
                this.notification = type;
            }),
            closeNotification: action((type) => {
                // check for 'string' in case first argument is an event
                if (!type || ((typeof type === 'string') && (this.notification === type))) {
                    this.notification = null;
                    clearTimeout(this.quickNotificationTm);
                }
            }),
            toggleNotification: action((type) => {
                if (this.notification === type) {
                    this.closeNotification();
                } else {
                    this.showNotification(type);
                }
            }),

            isPageOverlayVisible: () => [
                BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION,
                BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION,
                BrowserStore.NOTIFICATION.PAUSING_SESSION,
                BrowserStore.NOTIFICATION.SESSION_PAUSED,
                BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED,
                BrowserStore.NOTIFICATION.NEW_CONFIRM_EXIT_ISOLATION,
            ].includes(this.notification),

            quickNotificationTm: null,
            showQuickNotification: (type, timeout = 3000) => {
                clearTimeout(this.quickNotificationTm);
                this.showNotification(type);
                this.quickNotificationTm = setTimeout(this.closeNotification, timeout, type);
            },

            topBarMenu: new FlagStore(false),
            topBarUnpinned: sync(
                new FlagStore(true, { trackDirty: true }),
                LS_KEY.topBarMinimizable
            ),
            isTopBarMinimizable: () => { // TODO: why? when flag exists
                return this.topBarUnpinned.getValue();
            },
            topBarNeedsMinimization: new FlagStore(false),
            topBarMinificationAllowedCache: null,
            refreshTopBarMinificationAllowedCache: (value) => {
                const newValue = typeof value !== 'undefined' ? value :
                    this.isTopBarMinificationAllowed({ calculate: true });
                this.topBarMinificationAllowedCache = newValue;
            },
            isTopBarMinificationAllowed(opts = { calculate: false }) {
                const sts = this.getStores();
                if (this.notification === BrowserStore.NOTIFICATION.ENTER_ISOLATION_MESSAGE) {
                    return false;
                }
                const result = (!opts.calculate && this.topBarMinificationAllowedCache != null) ?
                    this.topBarMinificationAllowedCache : (
                        !this.isFocused() &&
                        !this.topBarNeedsMinimization.isTrue &&
                        !this.notification &&
                        !this.isHomePage &&
                        !(sts.research && sts.research.isFocused)
                    );
                return result;
            },
            settingsModal: new FlagStore(false),
            adBlock: new FlagStore(this.getDefault(LS_KEY.adBlock, data.blockAdsByDefault)),
            adBlockModal: new FlagStore(false),
            errorPage: new FlagStore(false),

            popupData: null,

            faqModal: new FlagStore(false),
            faqSection: null,

            accessCodeSettingsStore: new AccessCodesSettingsStore(),
            get hasPage() {
                // TODO: actually check for loaded page
                return this.frameEl !== null;
            },

            isDeveloper: !!DEBUG,

            networkState: null,

            webmail: new WebmailStore({}, this.useLocalStorage),

            reserveBrowser: () => {
                if (!this.useLocalStorage) {
                    return;
                }

                if (Date.now() > LocalStorage.get(LS_KEY.lastBrowserReserveApiCall, 0) + 40 * 1000) {
                    LocalStorage.set(LS_KEY.lastBrowserReserveApiCall, Date.now());
                    this.reserveBrowserApi().catch(debug.warn);
                }
            },

            homePageTabId: 0,
            setHomePageTabId: (newId) => {
                this.homePageTabId = newId;
            },

            // SESSION TIMEOUT
            inactivity: new InactivityStore({}, this.useLocalStorage),

            requestedMailtoUrl: null,
            requestSetClipboardText: null,

            sendFeedbackStore: new SendFeedbackStore({
                getFrameEl: () => this.frameEl,
            }),
            troubleshootingMode: false,
            troubleshootingCaseId: '',
            troubleshootingInstructionsScreen: false,
            troubleshootingInstructionsModal: new FlagStore(false),

            socketState: { ...defaultSocketState },

            inputRestrictions: BrowserStore.INPUT_RESTRICTION.NONE,
            inputRestrictionsDetails: new FlagStore(false),
            inputRestrictionsReason: null,
            isHarScanInputRestrictions: () => this.inputRestrictionsReason === 'HAR_SCANNING',

            isDownloadDisabled: true,
            isUploadDisabled: true,
            downloadsDisabledDetails: new FlagStore(false),
            uploadsDisabledDetails: new FlagStore(false),

            isHomePage: true,
            isLoading: false,
            isErrorPage: false,
            errorPageDetail: null,
            isAccessCodeApplyMode: new FlagStore(false),
            copyFromBrowserState({ iframeSrc, isLoading }) {
                this.isHomePage = !iframeSrc;
                this.isLoading = isLoading;
                // Set the initial state - this is the first moment where we know whether we're on the homepage or not.
                // We still want to check other settings though
                this.topBarNeedsMinimization.setValue(
                    !this.isTopBarMinimizable() || !this.isTopBarMinificationAllowed({ calculate: true })
                );
                this.refreshTopBarMinificationAllowedCache();
            },

            isResearchModeOn: () => (this.useLocalStorage ? LocalStorage.get(LS_KEY.researchMode) : false),

            canExitFromSafeState: true,
            exitAutomatically: false,

            sandboxScan: SCAN_STATUS.NONE,
            tsaScan: SCAN_STATUS.NONE,
            tiScan: SCAN_STATUS.NONE,
            fileScans: new Map(),
            subFrameScans: new Map(),
            subFrameMaliciousUrl: null,
            getAllScans: () => [
                this.tiScan,
                this.tsaScan,
                this.sandboxScan,
                ...this.fileScans.values(),
                ...this.subFrameScans.values()
            ],
            areAllScansDisabled: () => this.getAllScans().every((s) => s === SCAN_STATUS.NONE),
            isScanError: () => this.getAllScans().some((s) => s === SCAN_STATUS.ERROR),

            // "virtual" scan status for "new scan ux"
            getThreatScanStatus: () => {
                const tsaAndFileScans = [this.tsaScan, ...this.fileScans.values()];
                if ([this.sandboxScan, ...tsaAndFileScans].some((s) => s === SCAN_STATUS.BLOCK)) {
                    return SCAN_STATUS.BLOCK;
                }
                if (tsaAndFileScans.some((s) => s === SCAN_STATUS.SCANNING)) {
                    return SCAN_STATUS.SCANNING;
                }
                if (tsaAndFileScans.every((s) => s === SCAN_STATUS.NONE || s === SCAN_STATUS.DISABLED)) {
                    return SCAN_STATUS.NONE;
                }
                return SCAN_STATUS.CLEAN;
            },

            isDanger: () => this.getAllScans().some((s) => s === SCAN_STATUS.BLOCK),
            isSafe: () => this.getAllScans().every(
                (scan) => [SCAN_STATUS.NONE, SCAN_STATUS.CLEAN, SCAN_STATUS.DISABLED].includes(scan)
            ),
            isPhishing: () => this.tsaScan === SCAN_STATUS.SUSPECT,

            getVisualState: () => {
                if (this.isDanger()) {
                    return 'danger';
                }
                if (this.isPhishing()) {
                    return 'warn';
                }
                return 'scanning';
            },

            directDownloadPage: new FlagStore(false),
            mirrorFeatureFlags: new Set(),
            activityLoggerEvent: null,
            navigateToUrl(url) {
                setTimeout(() => { this.showNotification(BrowserStore.NOTIFICATION.COPY_URL_TO_EXIT); }, 1000);
                if (!this.exitIsolationStatus.isLoading) {
                    if (this.exitIsolationStatus.successCount === 0) {
                        this.exitIsolationStatus.setLoading(EventLogger.sendExitIsolationEvent(
                            url,
                            this.mirrorFeatureFlags.has('ACTIVITY_LOGGER_ENABLED') ?
                                this.activityLoggerEvent : null
                        ).finally(() => {
                            window.location = url;
                        }));
                    } else {
                        window.location = url;
                    }
                }
            },

            navigateToOriginalUrl() {
                this.navigateToUrl(this.getOriginalUrlByClickId(this.sandboxClickId) || this.urlInputValue);
            },

            notWebmailError: new FlagStore(false),
            notSecureWarning: new FlagStore(false),
            tsaBlockPage: new FlagStore(false),
            contentFilteringBlockPage: new FlagStore(false),

            // upload/download
            fileManagerStore: new FileManagerStore({}, this.useLocalStorage),
            uploadPopup: new FlagStore(false),

            // ud block page
            showExternalBlockPageOnDanger: false,
            blockPage: this.useLocalStorage ? LocalStorage.get(LS_KEY.udBlockPage) : null,
            shouldDisplayBlockPage: () => !!(this.showExternalBlockPageOnDanger
                && this.isDanger()
                && this.blockPage
                && canShowBlockPageInTheIframe(this.blockPage)),

            canExitIsolation: () => this.isSafe() && this.canExitFromSafeState
                && [BrowserStore.SCAN_STATUS.CLEAN, BrowserStore.SCAN_STATUS.DISABLED].includes(this.tsaScan)
                && !this.contentFilteringBlockPage.isTrue,

            minimized: false,
            getOriginalUrlByClickId(clickId) {
                return LocalStorage.getRaw('original_url_' + clickId);
            },
            isMinimized() {
                return this.minimized && !this.isDanger() && !this.canExitIsolation();
            },
            getPageStatus() {
                if (this.isLoading) {
                    return BrowserStore.PAGE_STATUS.NONE;
                }
                if (this.notWebmailError.isTrue) {
                    return BrowserStore.PAGE_STATUS.NOT_WEB_MAIL;
                }
                if (this.isDanger()) {
                    return BrowserStore.PAGE_STATUS.MALICIOUS_SITE;
                }
                if (this.fileScans.size === 0) {
                    if (this.isHomePage) {
                        return BrowserStore.PAGE_STATUS.NONE;
                    }
                    if (this.isErrorPage) {
                        return BrowserStore.PAGE_STATUS.ERROR_PAGE;
                    }
                }
                if (this.isScanError()) {
                    return BrowserStore.PAGE_STATUS.SCAN_ERROR;
                }
                if (this.isPhishing()) {
                    return BrowserStore.PAGE_STATUS.PHISHING_SITE;
                }
                if (this.getAllScans().some((s) => s === SCAN_STATUS.SCANNING)) {
                    return BrowserStore.PAGE_STATUS.THREAT_SCAN;
                }
                if (this.notSecureWarning.isTrue) {
                    return BrowserStore.PAGE_STATUS.NOT_SECURE_SITE;
                }
                return BrowserStore.PAGE_STATUS.SECURE_SITE;
            },

            // ids shared by multiple components
            adBlockTopBarButtonId: makeElementId('adBlockTopBarButton'),
            useFileThreatRules: false,
        }, data);

        // sync AdBlock option with other tabs
        reaction(() => this.adBlock.value, (newValue) => {
            if (this.useLocalStorage) {
                LocalStorage.set(LS_KEY.adBlock, newValue);
            }
        });

        reaction(() => this.urlInputFocus.value, (isFocused) => {
            if (isFocused) {
                this.canSelectFullUrlInputOnMouseUp = true;
            }
        });

        reaction(
            () => this.isTopBarMinificationAllowed({ calculate: true }),
            this.refreshTopBarMinificationAllowedCache,
            { delay: 100 }
        );

        // close toggles in right side notification when it is closed
        reaction(() => this.notification, (notification) => {
            if (notification !== BrowserStore.NOTIFICATION.DEFAULT_RIGHT) {
                this.inputRestrictionsDetails.close();
                this.downloadsDisabledDetails.close();
                this.uploadsDisabledDetails.close();
            }
        });

        // display notification when inactivity countdown starts
        reaction(() => this.inactivity.timeLeft !== -1, (countdownStarted) => {
            if (countdownStarted) {
                this.cancelBasicAuth();
                this.callClientToBrowserMethod(CLIENT_WINDOW_METHODS.exitFullscreen);
                this.showNotification(BrowserStore.NOTIFICATION.PAUSING_SESSION);
            }
        });

        reaction(
            () => this.inactivity.timeLeft === 0,
            (isCountedToZero) => isCountedToZero && this.updateSocketState({
                status: 'closed',
                reason: 'destroyedDueToInactivity',
            })
        );

        // close inactivity notification when countdown ends
        reaction(() => this.inactivity.timeLeft === -1, (countdownEnded) => {
            if (countdownEnded) {
                this.closeNotification(BrowserStore.NOTIFICATION.PAUSING_SESSION);
                if (this.socketState.reason !== 'destroyedDueToInactivity') {
                    this.showNotification(BrowserStore.NOTIFICATION.PAUSING_SESSION_CANCELLED);
                    setTimeout(this.closeNotification, 3000, BrowserStore.NOTIFICATION.PAUSING_SESSION_CANCELLED);
                    sendToClient(this.frameEl, API_BROWSER_TO_CLIENT.extendSession);
                }
            }
        });

        // sync troubleshooting with other tabs
        reaction(() => this.troubleshootingCaseId, (newValue) => {
            if (this.useLocalStorage) {
                LocalStorage.set(LS_KEY.troubleshootingCaseId, newValue);
            }
        });

        reaction(() => this.isDanger(), (isDanger) => {
            if (isDanger) {
                if (this.notification !== BrowserStore.NOTIFICATION.DEFAULT_LEFT) {
                    this.closeNotification();
                }
                if (this.showExternalBlockPageOnDanger
                    && this.blockPage
                    && !canShowBlockPageInTheIframe(this.blockPage)
                ) {
                    window.location.replace(this.blockPage);
                }
            }
        });

        // expand left notification to show scan error
        reaction(() => this.isScanError(), (isError) => {
            if (isError) {
                this.showNotification(BrowserStore.NOTIFICATION.DEFAULT_LEFT);
            }
        });

        if (useLocalStorage) {
            window.addEventListener('storage', (event) => {
                if (event.key === LS_KEY.adBlock) {
                    this.adBlock.setEnabled(LocalStorage.get(LS_KEY.adBlock, data.blockAdsByDefault));
                }
                if (event.key === LS_KEY.troubleshootingCaseId) {
                    this.troubleshootingCaseId = LocalStorage.get(LS_KEY.troubleshootingCaseId, '');
                    this.troubleshootingMode = !!this.troubleshootingCaseId;
                }
            });
        }

        // when user clicks on input first time, select full text
        this.canSelectFullUrlInputOnMouseUp = false;

        // customer defined message
        if (this.originalQuery.notification) {
            this.showNotification(BrowserStore.NOTIFICATION.CUSTOMER_MESSAGE);
            setTimeout(this.closeNotification, 10 * 1000, BrowserStore.NOTIFICATION.CUSTOMER_MESSAGE);
        }

        // url isolation human check
        if (this.useLocalStorage && this.notification === BrowserStore.NOTIFICATION.HUMAN_CHECK) {
            reaction(() => this.notification !== BrowserStore.NOTIFICATION.HUMAN_CHECK, (humanCheckClosed) => {
                if (humanCheckClosed) {
                    registerUserGesture();
                }
            });
        }
    }
    isFocused = () => (this.urlInputFocus.isTrue
        || this.topBarMenu.isOpen
        || this.settingsModal.isOpen
        || this.adBlockModal.isOpen
        || this.faqModal.isOpen
        || this.isPageOverlayVisible()
        || this.customBrandingInfoDropdown.isOpen
        || this.notification === BrowserStore.NOTIFICATION.DEFAULT_LEFT
        || this.notification === BrowserStore.NOTIFICATION.DEFAULT_RIGHT);


    setTroubleshootingMode = action((value) => {
        this.troubleshootingMode = value;
        if (!value) {
            this.troubleshootingCaseId = '';
            this.troubleshootingInstructionsScreen = false;
        }
    });

    setTroubleshootingInstructionsScreen = action((value) => {
        this.troubleshootingInstructionsScreen = value;
    });

    showErrorPage(message = '- unknown problem -', location) {
        this.inactivity.stopCountdown();
        this.errorPageApi({
            message: 'Failed to initialize the page. ' + message,
            location,
            ua: navigator.userAgent,
        });
        this.errorPage.show();
    }

    getDefault(lsKey, fallback) {
        return this.useLocalStorage
            ? LocalStorage.get(lsKey, fallback)
            : fallback;
    }

    // URL Input
    setUrlInputEl = action((el) => {
        this.urlInputEl = el;
        if (this.urlInputEl !== null && this.shouldFocusInputEl) {
            this.shouldFocusInputEl = false;
            focusEl(this.urlInputEl);
        }
    });

    focusUrlInput = action(() => {
        if (this.urlInputEl !== null) {
            this.shouldFocusInputEl = false;
            focusEl(this.urlInputEl);
        } else {
            this.shouldFocusInputEl = true;
        }
    });

    submitUrlInput = action(() => {
        if (this.urlInputEl !== null) {
            this.setUrlInputValue(this.urlInputEl.value);
            this.urlInputEl.blur();
        }
    });

    setUrlInputValue = action((newValue) => {
        newValue = (newValue || '').trim();

        if (this.urlInputValue === newValue) {
            return;
        }

        this.urlInputValue = newValue;

        if (this.urlInputEl) {
            this.urlInputEl.value = this.urlInputValue;
        }
    });

    updateUrlInputValue = action(() => {
        if (this.urlInputEl !== null) {
            this.urlInputValue = this.urlInputEl.value;
        }
    });

    onUrlInputMouseUp = action(() => {
        if (this.urlInputEl !== null && this.canSelectFullUrlInputOnMouseUp) {
            this.canSelectFullUrlInputOnMouseUp = false;
            if (this.urlInputEl.selectionStart === this.urlInputEl.selectionEnd) {
                this.urlInputEl.select(0, this.urlInputEl.value.length);
            }
        }
    });

    setFrameEl = action((el) => {
        if (el) {
            this.frameEl = el;
        } else {
            this.frameEl = null;
        }
    });

    printPage = () => {
        if (this.frameEl) {
            const frameWindow = this.frameEl.contentWindow;
            if (frameWindow) {
                frameWindow.focus();
                frameWindow.print();
            }
        }
    };

    setDiagnostics = (value) => {
        diagnostics = value;
    };

    getDiagnostics = () => {
        return diagnostics;
    };

    openMailClient = action(() => {
        this.closeNotification(BrowserStore.NOTIFICATION.EMAIL_PERMISSION);

        if (this.requestedMailtoUrl) {
            const win = window.open(this.requestedMailtoUrl);

            if (win) {
                setTimeout(() => {
                    try {
                        if (win.location.href === '' || win.location.href === 'about:blank') {
                            debug.log('desktop client opened and the new tab is unnecessary');
                            win.close();
                        } else {
                            debug.log('web client (like Gmail) loaded instead of desktop client');
                        }
                    } catch (e) {
                        debug.log('web client (like Gmail) loaded instead of desktop client');
                    }
                }, 1000);
            }
        }
    });

    callClientToBrowserMethod = (method) => {
        const { frameEl } = this;

        try {
            if (frameEl && frameEl.contentWindow && frameEl.contentWindow[method]) {
                frameEl.contentWindow[method]();
            }
        } catch (e) {
            debug.warn('callClientToBrowserMethod', e);
        }
    };

    openFullscreen = () => {
        this.closeNotification(BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION);
        this.callClientToBrowserMethod(CLIENT_WINDOW_METHODS.openFullscreen);
    };

    exitFullscreen = () => {
        this.closeNotification(BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION);
        this.callClientToBrowserMethod(CLIENT_WINDOW_METHODS.exitFullscreen);
    };

    cancelBasicAuth = () => {
        this.closeNotification(BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION);
        this.basicAuth.cancel(this.frameEl);
        this.basicAuth.clear();
    };

    sendBasicAuth = () => {
        this.closeNotification(BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION);
        this.basicAuth.send(this.frameEl);
        this.basicAuth.clear();
    };

    allowSetClipboard = action(() => {
        if (this.requestSetClipboardText) {
            copyTextOnClick(this.requestSetClipboardText);
        }

        this.closeNotification(BrowserStore.NOTIFICATION.CLIPBOARD_PERMISSION);
    });

    setViewport = action((viewport) => {
        // Special zoomed Viewports are needed on mobile only
        // for pages that do not have <meta viewport> tag that enables "mobile view"
        if (!IS_MOBILE) {
            return;
        }

        const screen = Screen.getCurrentScreen();

        if (!Screen.isEqualOrientation(screen, viewport)) {
            debug.log('Orientation change is in progress');
            return;
        }

        if (!Screen.isEqualSize(this.viewport, viewport)) {
            this.screen = screen;
            this.viewport = viewport;
        }
    });

    updateSocketState = action((update = defaultSocketState) => {
        if (this.isAccessCodeApplyMode.value) {
            debug.log('User has applied access code');
            return;
        }

        const prevStatus = this.socketState.status;
        this.socketState = Object.assign({}, this.socketState, update);
        const { status } = this.socketState;
        const isDestroyed = this.socketState.reason === 'destroyedDueToInactivity';
        const isThreat = this.socketState.reason === 'destroyedDueToThreat';

        if (isDestroyed) {
            this.networkState = BrowserStore.NOTIFICATION.SESSION_PAUSED;
            this.showNotification(BrowserStore.NOTIFICATION.SESSION_PAUSED);
        } else if (isThreat) {
            this.networkState = BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED;
            this.showNotification(BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED);
        } else if (status === 'error' || status === 'closed') {
            this.networkState = BrowserStore.NOTIFICATION.DISCONNECTED;
            this.showNotification(BrowserStore.NOTIFICATION.DISCONNECTED);
        } else if (status === 'reconnecting') {
            if (this.socketState.reconnectCounter >= 10) {
                this.networkState = BrowserStore.NOTIFICATION.DISCONNECTED;
                this.showNotification(BrowserStore.NOTIFICATION.DISCONNECTED);
            } else {
                this.networkState = BrowserStore.NOTIFICATION.TRYING_TO_CONNECT;
                this.showNotification(BrowserStore.NOTIFICATION.TRYING_TO_CONNECT);
            }
        } else if (status === 'opened') {
            this.networkState = BrowserStore.NOTIFICATION.CONNECTION_ESTABLISHED;
            if (prevStatus !== 'opened' && prevStatus !== 'wait') {
                this.showQuickNotification(BrowserStore.NOTIFICATION.CONNECTION_ESTABLISHED);
            }
        } else if (status === 'wait') {
            this.networkState = null;
            this.closeNotification(BrowserStore.NOTIFICATION.SESSION_PAUSED);
            this.closeNotification(BrowserStore.NOTIFICATION.DISCONNECTED);
            this.closeNotification(BrowserStore.NOTIFICATION.TRYING_TO_CONNECT);
            this.closeNotification(BrowserStore.NOTIFICATION.CONNECTION_ESTABLISHED);
        }

        if (prevStatus === 'opened' && status !== 'opened') {
            this.exitFullscreen();
            this.cancelBasicAuth();
        }

        if (this.sandboxScan === SCAN_STATUS.SCANNING &&
            (isDestroyed || status === 'closed' || status === 'error')) {
            this.waitSandboxTimeout();
        }
    });

    checkUrlInThreatIntelligence = action(() => {
        this.checkUrlScoreTm = 0;

        if (this.sandboxScan !== SCAN_STATUS.SCANNING) {
            return Promise.resolve();
        }

        if (!this.checkUrlScoreStatus.isLoading) {
            this.checkUrlScoreStatus.setLoading(this.checkUrlScore({
                url: this.urlInputValue,
                clickId: this.sandboxClickId,
            }))
                .then((scanResult) => {
                    this.onSandboxScanResult(scanResult);
                })
                .catch((error) => {
                    debug.error('UrlInThreatIntelligence result error', error.message);
                    this.onSandboxScanResult('allow');
                });
        }

        return this.checkUrlScoreStatus.promise;
    });

    waitSandboxTimeout = action(() => {
        const timeoutMs = this.pageOpenedTs - Date.now() + this.sandboxTimeout * 1000;

        if (timeoutMs < 0) {
            this.checkUrlInThreatIntelligence();
        } else {
            this.checkUrlScoreTm = setTimeout(() => this.checkUrlInThreatIntelligence(), timeoutMs);
        }
    });

    openFaq = action((section) => {
        this.closeNotification();
        this.faqSection = section;
        this.faqModal.open();
    });

    setDLP = action((dlp = undefined) => {
        let newInputRestrictions;
        if (!dlp) {
            newInputRestrictions = BrowserStore.INPUT_RESTRICTION.NONE;
        } else if (dlp.allInput) {
            newInputRestrictions = BrowserStore.INPUT_RESTRICTION.INPUT_DISABLED;
        } else if (dlp.clipboardPaste) {
            newInputRestrictions = BrowserStore.INPUT_RESTRICTION.PASTE_DISABLED;
        } else if (dlp.pasteLimitEnabled && dlp.pasteLimit > 0) {
            newInputRestrictions = BrowserStore.INPUT_RESTRICTION.PASTE_TRUNCATED;
        } else {
            newInputRestrictions = BrowserStore.INPUT_RESTRICTION.NONE;
        }

        // close current input restrictions notification if restrictions change
        if (this.inputRestrictions !== newInputRestrictions) {
            this.closeNotification(this.inputRestrictions);
        }

        this.inputRestrictions = newInputRestrictions;
        this.inputRestrictionsReason = dlp ? dlp.reason : null;

        if (
            this.useRedesignedPolicy &&
            dlp &&
            !this.useFileThreatRules &&
            (typeof dlp.allowDownloads !== 'undefined' || typeof dlp.allowUploads !== 'undefined')
        ) {
            this.isDownloadDisabled = !dlp.allowDownloads;
            this.isUploadDisabled = !dlp.allowUploads;
        }
    });

    // NOT used when new policy is enabled. All - input and u/d settings is shoved into the same DLP object
    setDownloadUploadDLP = action((dlp) => {
        this.isDownloadDisabled = !dlp || !dlp.allowDownloads;
        this.isUploadDisabled = !dlp || !dlp.allowUploads;
    });

    onSandboxScanResult = action((scanResult) => {
        debug.alwaysLog('onSandboxScanResult', scanResult);

        if (scanResult === 'block') {
            this.sandboxScan = SCAN_STATUS.BLOCK;
        }
    });

    // magical strings here are not same as SCAN_STATUS enum
    // SCAN_STATUS enum is inspired by TSA scan disposition values
    // see proofpoint TSA wiki for all possible values
    onTsaScanResult = action((scanResult) => {
        logger.alwaysLog('onTsaScanResult', scanResult);

        if (scanResult === 'SCANNING') {
            this.tsaScan = SCAN_STATUS.SCANNING;
        } else if (scanResult === 'ERROR') {
            this.tsaScan = SCAN_STATUS.ERROR;
        } else if (scanResult === 'SUSPECT') {
            if (this.tsaScan !== SCAN_STATUS.SUSPECT && this.tsaScan !== SCAN_STATUS.BLOCK) {
                this.tsaScan = SCAN_STATUS.SUSPECT;
                this.showNotification(BrowserStore.NOTIFICATION.DEFAULT_LEFT);
            }
        } else if (scanResult === 'BLOCK') {
            this.tsaScan = SCAN_STATUS.BLOCK;
            if (!this.isResearchModeOn()) {
                this.tsaBlockPage.show();
                if (this.sandboxScan === SCAN_STATUS.SCANNING) {
                    this.sandboxScan = SCAN_STATUS.NONE;
                }
            }
        } else if (scanResult === 'CLEAN') {
            this.tsaScan = SCAN_STATUS.CLEAN;

            if (this.exitAutomatically) {
                this.navigateToOriginalUrl();
            }
        } else if (scanResult === 'DISABLED') {
            this.tsaScan = SCAN_STATUS.DISABLED;

            if (this.exitAutomatically) {
                this.navigateToUrl(this.urlInputValue);
            }
        } else {
            debug.recordError('unknown tsa scan result', scanResult);
        }
    });

    onTiScanResult = action((scanResult) => {
        logger.alwaysLog('onTiScanResult', scanResult);

        if (scanResult === SCAN_STATUS.BLOCK) {
            this.tiScan = SCAN_STATUS.BLOCK;
            if (this.tsaScan === SCAN_STATUS.SCANNING) {
                this.tsaScan = SCAN_STATUS.NONE;
            }
            if (this.sandboxScan === SCAN_STATUS.SCANNING) {
                this.sandboxScan = SCAN_STATUS.NONE;
            }
        } else if (Object.values(SCAN_STATUS).includes(scanResult)) {
            this.tiScan = scanResult;
        } else {
            debug.recordError('unknown ti scan result', scanResult);
        }
    });

    onFileScanResult = action((downloadId, scanResult) => {
        logger.alwaysLog('onFileScanResult', downloadId, scanResult);

        if (scanResult === 'SCANNING') {
            this.fileScans.set(downloadId, SCAN_STATUS.SCANNING);
            // pol for scan status while on an error page
            if (this.isErrorPage) {
                pollScanStatus(`fileScanResult-${downloadId}`,
                    this.sandboxClickId || 'browser-isolation',
                    (result) => {
                        if (this.fileScans.has(downloadId)) {
                            this.onFileScanResult(downloadId, result);
                        }
                    });
            }
        } else if (scanResult === 'BLOCK') {
            this.fileScans.set(downloadId, SCAN_STATUS.BLOCK);
            if (!this.isResearchModeOn()) {
                this.tsaBlockPage.show();
                if (this.sandboxScan === SCAN_STATUS.SCANNING) {
                    this.sandboxScan = SCAN_STATUS.NONE;
                }
            }
        } else if (scanResult === 'CLEAN') {
            this.fileScans.set(downloadId, SCAN_STATUS.CLEAN);
        } else {
            // Note - SUSPECT case would end up here, BUT the client should never see it, as we change it on the server
            // browser
            if (this.fileScans.has(downloadId)) {
                this.fileScans.set(downloadId, SCAN_STATUS.CLEAN);
            }
            debug.recordError('unknown file scan result', { downloadId, scanResult });
        }
    });

    onSubFrameScanResult = action(({ status, url }) => {
        logger.alwaysLog('onSubFrameReputationScanResult', status, url);

        if (status === SCANNING_STATE.SCANNING) {
            this.subFrameScans.set(url, SCAN_STATUS.SCANNING);
        } else if (status === SCANNING_STATE.BLOCKED) {
            this.subFrameScans.set(url, SCAN_STATUS.BLOCK);
            this.tiScan = SCAN_STATUS.BLOCK;
            this.subFrameMaliciousUrl = url;
            if (!this.isResearchModeOn()) {
                this.tsaBlockPage.show();
            }
        } else if (status === SCANNING_STATE.ALLOWED) {
            this.subFrameScans.set(url, SCAN_STATUS.CLEAN);
        } else {
            if (this.subFrameScans.has(url)) {
                this.subFrameScans.set(url, SCAN_STATUS.CLEAN);
            }
            debug.recordError('unknown sub-frame reputation scan result', { url, status });
        }
    });

    setPageOffset = action((offset) => {
        if (offset === this.pageOffset) {
            return;
        }
        this.minimized = offset > this.pageOffset;
        this.pageOffset = offset;
    });

    resetPageState = action(() => {
        clearTimeout(this.checkUrlScoreTm);
        this.pageOpenedTs = 0;
        this.inactivity.stopCountdown();
        this.isErrorPage = false;
        this.errorPageDetail = null;
        this.errorPage.close();
        this.notWebmailError.close();
        this.notSecureWarning.close();
        this.tsaBlockPage.close();
        this.tsaScan = SCAN_STATUS.NONE;
        this.tiScan = SCAN_STATUS.NONE;
        this.fileScans.clear();
        this.subFrameScans.clear();
        this.subFrameMaliciousUrl = null;
        this.setDLP();
        this.updateSocketState();

        if (this.useRedesignedPolicy) {
            this.canExitFromSafeState = false;
        }
    });

    updateFromPageAttributes = action(({ location, error }) => {
        this.notSecureWarning.setValue(location && !(location + '').startsWith('https'));
        this.isErrorPage = error !== undefined;
        this.errorPageDetail = error;
    });

    showAndSaveFileProcessNotification = action((event) => {
        if (this.notification !== BrowserStore.NOTIFICATION.FILE_PROCESSING) {
            this.fileManagerStore.clearScanningNotifications();
        }
        this.fileManagerStore.pushNewFileEventData(event, this.closeNotification, this.showNotification);
    });

    setExitIso = action(({ canExitFromSafeState, exitAutomatically, displayExitWarning }) => {
        this.exitAutomatically = !!exitAutomatically;
        this.displayExitWarning = !!displayExitWarning;
        this.canExitFromSafeState = !!canExitFromSafeState;
    });

    setRole = action((role) => {
        logger.info('Browsing role update', role);
        this.settingsResearchMode = role.allowResearchMode;
        this.roleName = role.name;
    });

    setExitIsoText = action((text) => {
        this.exitWarningText = text ?? null;
    });

    setEnterIso = action((opt) => {
        this.enterIso = opt ?? null;
    });

    /**
     * Is used by UrlIsolation only
     * Url for Exit Isolation Button or auto-exit
     */
    setOriginalUrl = (originalUrl, clickId) => {
        if (!originalUrl || !clickId) {
            return;
        }

        if (this.getOriginalUrlByClickId(clickId)) {
            // already been set
            return;
        }

        setOriginalUrlByClickId(originalUrl, clickId);
    };
}
