import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import b from 'b_';
import BrowserStore from '../../../browser/stores/BrowserStore';
import FuseButton from '../../FuseControls/FuseButton';
import { EnvStoreProps } from '../../../stores/props';
import './UDExitIsolation.css';

const block = b.lock('UDExitIsolation');

class UDExitIsolation extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStoreProps,
        className: PropTypes.string,
    };

    onExitClick = (e) => {
        e.stopPropagation();

        const { browser } = this.props;
        if (browser.displayExitWarning) {
            browser.topBarMenu.close();
            browser.showNotification(BrowserStore.NOTIFICATION.NEW_CONFIRM_EXIT_ISOLATION);
        } else if (browser.tsaScan === BrowserStore.SCAN_STATUS.SUSPECT) {
            browser.showNotification(BrowserStore.NOTIFICATION.CONFIRM_EXIT_ISOLATION);
        } else {
            browser.navigateToOriginalUrl();
        }
    };

    render() {
        const { t, browser, env, className } = this.props;
        const minified = browser.isTopBarMinificationAllowed();
        const hideText = env.screen.isMobile || minified;

        return <FuseButton
            iconRight="open_in_new"
            type="quaternary"
            className={cn(block({ minified, hideText, mobile: env.screen.isMobile }), className)}
            wrapperClassName={block('FuseButtonWrapper')}
            iconRightClassName={block('FuseButtonIcon')}
            extraSmall={minified}
            noCenter={hideText}
            onClick={this.onExitClick}
            title={t('UDTopBar.newExit')}
        >{hideText ? null : t('UDTopBar.newExit')}</FuseButton>;
    }
}

export default translate()(inject('browser', 'env')(observer(UDExitIsolation)));
