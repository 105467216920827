import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import PropTypes from 'prop-types';
import BrowserStore from '../../browser/stores/BrowserStore';
import { BrandingOption, HelpButton, HelpButtonAction } from '../../stores/BrowserBarOptions';
import { EnvStoreProps, ResearchStoreProp } from '../../stores/props';
import CustomerContent from '../CustomerContent';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import FuseButton from '../FuseControls/FuseButton';
import Modal from '../Modal';
import Box from '../Box/Box';
import { TBody } from '../Text/Text';
import { LogoProofpointWord } from '../Logo/PfptLogo';
import TopBarDropdown from '../TopBarDropdown/TopBarDropdown';
import HistoryStore from '../../browser/stores/HistoryStore';
import './TopBarLogo.css';
import '../TopBarGuide.css';

const block = b.lock('TopBarLogo');
const guideBlock = b.lock('TopBarGuide');

class TopBarLogo extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStoreProps,
        history: HistoryStore.PropType.isRequired,
        isWizard: PropTypes.bool,
        research: ResearchStoreProp,
    }

    openHelp = () => {
        const { helpButtonAction, externalLink } = this.props.browser.browserBarOptions;
        if (helpButtonAction === HelpButtonAction.LINK) {
            window.open(externalLink, '_blank');
        } else if (helpButtonAction === HelpButtonAction.POPUP) {
            this.props.browser.customBrandingInfoDropdown.toggle();
        }
    }

    onBrandingClick = (event) => {
        if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
            return;
        }

        const { browserBarOptions } = this.props.browser;
        if (browserBarOptions.brandingOption === BrandingOption.CUSTOM
            && browserBarOptions.helpButton === HelpButton.BRANDING
        ) {
            this.openHelp();
        } else {
            this.props.history.openHomePage();
        }
    };

    getBrandingTitle() {
        const { t, browser } = this.props;
        const { browserBarOptions } = browser;

        if (browserBarOptions.brandingOption === BrandingOption.CUSTOM
            && browserBarOptions.helpButton === HelpButton.BRANDING
        ) {
            const { brandingOption, customBrandingText } = browserBarOptions;
            const isCustomBranding = brandingOption === BrandingOption.CUSTOM;

            return isCustomBranding && customBrandingText ? customBrandingText : this.getLogoText();
        }

        return t('browserTopBar.homepageTitle');
    }

    getLogoText() {
        const { env } = this.props;

        if (env.isUrlIsolation) {
            return 'URL Isolation';
        }
        if (env.isSaasIsolation) {
            return 'SaaS Isolation';
        }
        return 'Isolation';
    }

    renderLogo(textOnly = false) {
        const { env, research, browser } = this.props;
        const isTopBarMinified = browser.isTopBarMinificationAllowed();
        const hidden = env.screen.isMobile && isTopBarMinified;

        const hideText =
            (env.screen.isMobile && env.isUrlIsolation && browser.canExitIsolation()) ||
            (env.screen.isSmallDesktop && research.researchMode.isEnabled);
        const logoText = this.getLogoText();

        return <div className={block('Logo', { textOnly, hideText, minified: isTopBarMinified, hidden })}>
            <LogoProofpointWord className={block('LogoPfpt', {
                minified: isTopBarMinified,
            })}
            />
            {!hideText ? <div className={block('LogoText', {
                minified: isTopBarMinified,
            })}
            >{logoText}</div> : null}
        </div>;
    }

    renderCustomInfo() {
        const { env, browser } = this.props;
        return (env.screen.isMobile || env.isTouchDevice) && !browser.isConsolePreview
            ? this.renderCustomInfoPopup()
            : this.renderCustomInfoTopBar();
    }

    renderCustomInfoTopBar() {
        const { browser, t } = this.props;
        return <TopBarDropdown
            className={block('Dropdown')}
            onClose={!browser.isConsolePreview ? browser.customBrandingInfoDropdown.close : () => null}
            type="branding"
        >
            {browser.browserBarOptions.popupContent
                ? <FuseWysiwygReadOnly content={browser.browserBarOptions.popupContent} />
                : <CustomerContent html={browser.browserBarOptions.popupMessage} />}
            <FuseButton
                color="blue"
                type="primary"
                fullWidth
                onClick={browser.customBrandingInfoDropdown.close}
                style={{ marginTop: 20 }}
            >{t('TopBarLogo.close')}</FuseButton>
        </TopBarDropdown>;
    }
    renderCustomInfoPopup() {
        const { browser } = this.props;
        const { brandingOption, customBrandingText } = browser.browserBarOptions;
        const isCustomBranding = brandingOption === BrandingOption.CUSTOM;
        const modalTitle = isCustomBranding && customBrandingText ? customBrandingText : this.getLogoText();

        return <Modal
            isOpen
            onClose={!browser.isConsolePreview ? browser.customBrandingInfoDropdown.close : () => null}
        >
            <Box title={modalTitle}>
                {browser.browserBarOptions.popupContent
                    ? <FuseWysiwygReadOnly content={browser.browserBarOptions.popupContent} />
                    : <TBody><CustomerContent html={browser.browserBarOptions.popupMessage} /></TBody>}
            </Box>
        </Modal>;
    }

    renderBranding() {
        const { research, env, browser, isWizard } = this.props;
        const { brandingOption, helpButton, helpButtonAction, customBrandingText } = browser.browserBarOptions;

        const minified = browser.isTopBarMinificationAllowed();
        const isCustomBranding = brandingOption === BrandingOption.CUSTOM;
        const textOnly = env.isSaasIsolation || env.isUrlIsolation;

        const isClickable = (isCustomBranding && (helpButton === HelpButton.BRANDING))
            || env.isBrowserDefense || env.isWebmailDefense;

        const isBrandingPopupOpen = helpButton === HelpButton.BRANDING
            && helpButtonAction === HelpButtonAction.POPUP
            && browser.customBrandingInfoDropdown.isOpen;

        const branding = isCustomBranding
            ? <div
                className={block('CustomBranding', { smallFont: customBrandingText.length > 26 })}
                title={customBrandingText}
            >{customBrandingText}</div>
            : this.renderLogo(textOnly);

        return <div className={block('BrandingWrapper', { minified })}>
            {isClickable
                ? <FuseButton
                    type="quaternary"
                    disabled={minified}
                    noCenter
                    extraSmall={minified}
                    onClick={this.onBrandingClick}
                    title={this.getBrandingTitle()}
                    {...(research.researchMode.isEnabled ? { color: 'black' } : {})}
                    {...(isWizard ? { tabIndex: -1 } : {})}
                    className={block('Button')}
                    wrapperClassName={block('ButtonWrapper')}
                    noHalo={minified}
                    active={isBrandingPopupOpen}
                    role="navigation"
                    aria-label={this.getBrandingTitle()}
                >{branding}</FuseButton>
                : <div className={block('BrandingText', { minified })}>{branding}</div>}

            {isBrandingPopupOpen ? this.renderCustomInfo() : null}
        </div>;
    }

    render() {
        const { t, browser, env, isWizard } = this.props;
        const minified = browser.isTopBarMinificationAllowed();
        const hidden = env.screen.isMobile && minified;
        const { brandingOption, helpButton, helpButtonAction, customBrandingText } = browser.browserBarOptions;
        const isCustomBranding = brandingOption === BrandingOption.CUSTOM;
        const mobile = env.screen.isMobile;

        const hideBranding = isCustomBranding && !customBrandingText;

        const isIconPopupOpen = helpButton === HelpButton.ICON
            && helpButtonAction === HelpButtonAction.POPUP
            && browser.customBrandingInfoDropdown.isOpen;

        return <div className={block({ hidden, mobile, minified })}>
            {hideBranding ? null : this.renderBranding() }

            {helpButton === HelpButton.ICON ? <div className={block('HelpWrapper', { minified })}>
                <FuseButton
                    className={block('HelpButton')}
                    noHalo={minified}
                    disabled={minified}
                    icon="help_outline"
                    type="quaternary"
                    onClick={this.openHelp}
                    active={isIconPopupOpen}
                    title={t('browserTopBar.helpButtonTitle')}
                    aria-label={t('browserTopBar.helpButtonTitle')}
                />

                {isIconPopupOpen ? this.renderCustomInfo() : null}
            </div> : null}

            {isWizard ? <div className={guideBlock({ type: 'top', inBigButton: true })}>
                {t('browserTopBar.guides.homeButton')}
            </div> : null}
        </div>;
    }
}

export default translate()(inject('browser', 'research', 'env', 'history')(observer(TopBarLogo)));
