import PropTypes from 'prop-types';
import cn from 'classnames';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { CSSTransitionGroup } from 'react-transition-group';
import b from 'b_';
import { observer, inject } from 'mobx-react';
import CommonBrowserModals from '../../../Components/CommonBrowserModals/CommonBrowserModals';
import Button from '../../../Components/Button';
import makeElementId from '../../../makeElementId';
import BrowserStore from '../../stores/BrowserStore';
import SnackStore from '../../../stores/SnackStore';
import LocalStorage, { LS_KEY } from '../../../LocalStorage';
import BrowserSettingsModal from '../../../Components/BrowserSettingsModal/BrowserSettingsModal';
import TopBarAlternative from '../../../Components/TopBarAlternative/TopBarAlternative';
import { ResearchStoreProp, EnvStoreProps } from '../../../stores/props';
import BrowserHomeWebmail from '../../../Components/BrowserHomeWebmail/BrowserHomeWebmail';
import FAQContent from '../../../Components/FAQ/FAQContent';
import BrowserWelcome from '../../../Components/BrowserWelcome/BrowserWelcome';
import AdBlockerModal from '../../../Components/AdBlockerModal/AdBlockerModal';
import UserAgentMenu from '../../../Components/UserAgentMenu/UserAgentMenu';
import LocationMenu from '../../../Components/LocationMenu/LocationMenu';
import Actions from '../../../Components/Actions/Actions';
import { translateWithScreen } from '../../../Translate';
import { GenericErrorPage } from '../../../pages/BrowserErrorPages';
import ThePage from '../../../Components/ThePage/ThePage';
import UDSpinner from '../../../Components/UD/Fragments/UDSpinner';
import { TroubleshootingInstructions } from '../../../Components/TroubleshootingModeScreen';
import MaliciousThreatPage from '../../../Components/BlockPage/MaliciousThreatPage';
import './Browser.css';
import '../../../Components/MinimizableBrowser.css';

const block = b.lock('Browser');
const mBlock = b.lock('MinimizableBrowser');

class Browser extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
        iframeOpacity: PropTypes.number,
        env: EnvStoreProps,
        iframeSrc: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        loadingIsAborted: PropTypes.bool,
        onRefresh: PropTypes.func,
        onSubmit: PropTypes.func,
        onLoad: PropTypes.func,
        snack: SnackStore.PropType.isRequired,
    };

    static defaultProps = {
        // making it optional, for easy storybook integration
        onRefresh: () => {},
        onInputChange: () => {},
        onSubmit: () => {},
        onLoad: () => {},
    };

    tabIdPrefix = makeElementId('tab');
    tabPanelIdPrefix = makeElementId('tabpanel');

    componentDidMount() {
        if (!this.props.iframeSrc) {
            // turn off autofocus for mobile devices
            if (!this.props.env.screen.isMobile) {
                this.props.browser.focusUrlInput();
            }
            this.props.browser.reserveBrowser();

            if (LocalStorage.get(LS_KEY.isAccessCodeApplyMode, '')) {
                LocalStorage.remove(LS_KEY.isAccessCodeApplyMode);
                this.props.snack.showSuccess(this.props.t('settingsModal.accessCodes.successMessage'));
            }
        }
    }

    homeTabs = [{
        icon: 'home',
        key: 'home',
        renderTitle: () => this.props.t('Browser.tabHome'),
        renderContent: () => <BrowserHomeWebmail />,
    }, {
        icon: 'explore',
        key: 'welcome',
        renderTitle: () => this.props.t('Browser.tabWelcome'),
        renderContent: () => <BrowserWelcome />,
    }, {
        icon: 'help_outline',
        key: 'faq',
        renderTitle: () => translateWithScreen(this.props.t, this.props.env.screen.isMobile, 'Browser.tabFAQ'),
        renderContent: () => <div style={{ padding: '40px 10px 50px 10px', maxWidth: 900, margin: '0 auto' }}>
            <FAQContent />
        </div>,
    }];

    renderHomePage() {
        const { browser } = this.props;
        const research = this.props.research.researchMode.isEnabled;

        return <div className={block('Home')}>
            <Actions
                className={block('HomeTabs', { research })}
                wrapperClassName={block('HomeTabWrapper')}
                role="tablist"
            >
                {this.homeTabs.map((tab, i) => <Button
                    className={block('HomeTab', { active: browser.homePageTabId === i, research })}
                    onClick={() => browser.setHomePageTabId(i)}
                    icon={tab.icon}
                    iconClassName={block('HomeTabIcon')}
                    key={i}
                    role="tab"
                    id={`${this.tabIdPrefix}-${i}`}
                    aria-selected={browser.homePageTabId === i}
                    aria-controls={`${this.tabPanelIdPrefix}-${i}`}
                >{tab.renderTitle()}</Button>)}
            </Actions>
            <div
                className={block('HomeContent', { [this.homeTabs[browser.homePageTabId].key]: true })}
                role="tabpanel"
                id={`${this.tabPanelIdPrefix}-${browser.homePageTabId}`}
                aria-labelledby={`${this.tabIdPrefix}-${browser.homePageTabId}`}
            >
                {this.homeTabs[browser.homePageTabId].renderContent()}
            </div>
        </div>;
    }

    renderIframePage() {
        if (this.props.loadingIsAborted) {
            return null;
        }

        if (this.props.browser.errorPage.isOpen) {
            return <GenericErrorPage
                isUrlIsolation={this.props.env.isUrlIsolation}
                isSaasIsolation={this.props.env.isSaasIsolation}
            />;
        }

        if (this.props.browser.tsaBlockPage.isOpen) {
            if (this.props.browser.customMaliciousThreatBlockPage) {
                return <iframe
                    title="Malicious Threat Block Page"
                    src={`/page/custom_malicious_threat_block_page?_=${Date.now()}`}
                    className={block('BlockPage')}
                    name="block_page"
                />;
            }
            return <MaliciousThreatPage />;
        }

        if (this.props.browser.troubleshootingInstructionsScreen) {
            return <TroubleshootingInstructions />;
        }

        if (!this.props.iframeSrc) {
            return this.renderHomePage();
        }

        const { browser } = this.props;
        return <ThePage
            iframeSrc={this.props.iframeSrc}
            iframeOpacity={this.props.iframeOpacity}
            onLoad={this.props.onLoad}
            blur={this.props.isLoading || browser.topBarMenu.isOpen}
        />;
    }

    render() {
        const { browser, t, research, env, onRefresh } = this.props;
        const { isHomePage, notification } = browser;
        const hasBannerNotification = notification === BrowserStore.NOTIFICATION.ENTER_ISOLATION_MESSAGE;

        return <div className={cn(block(), mBlock({
            minimizable: !isHomePage && browser.isTopBarMinimizable(),
            mobile: env.screen.isMobile,
        }))}
        >
            <TopBarAlternative onRefresh={this.props.onRefresh} onSubmit={this.props.onSubmit} />

            <div className={block('AbsoluteContainer', { home: !this.props.iframeSrc, hasBannerNotification })}>
                {this.renderIframePage()}

                <CSSTransitionGroup
                    transitionName={block('OverlayAnimation').toString()}
                    transitionEnterTimeout={150}
                    transitionLeave={false}
                >
                    {this.props.isLoading && <div
                        key="overlay"
                        className={block('Overlay')}
                    >
                        <UDSpinner
                            className={block('Spinner')}
                            type="large"
                            title={t('Browser.spinnerTitle')}
                        />
                    </div>}
                </CSSTransitionGroup>
            </div>

            <CommonBrowserModals onRefresh={onRefresh} />

            <BrowserSettingsModal />
            {env.screen.isMobile && browser.adBlockModal.isOpen
                ? <AdBlockerModal afterChange={onRefresh} />
                : null}
            {env.screen.isTablet || env.screen.isMobile ? <Fragment>
                {research.userAgentMenu.isOpen
                    ? <UserAgentMenu afterChange={this.props.onRefresh} />
                    : null}
                {research.locationMenu.isOpen
                    ? <LocationMenu afterChange={this.props.onRefresh} />
                    : null}
            </Fragment> : null}
        </div>;
    }
}

export default translate()(inject('browser', 'research', 'env', 'snack')(observer(Browser)));
