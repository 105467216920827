import React from 'react';
import { render as originalRender } from 'react-dom';
import ReactModal from 'react-modal';
import { Router, Route, browserHistory } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'mobx-react';
import uuid from 'uuid/v4';
import 'normalize.css';
import '../reset.css';
import debug, { Raven, disableSentryNotifications, DEBUG } from '../debug';
import clientConfig from '../clientConfig';
import EnvStore from '../stores/EnvStore';
import LocalStorage, { LS_KEY } from '../LocalStorage';
import { isHuman } from './botDetector';
import BrowserErrorPage from './BrowserErrorPage';
import BrowserController from './Components/Browser/BrowserController';
import RootPage from '../RootPage';
import i18n from './i18n';
import initData from './browserInitData';
import initBrowserStores from './initBrowserStores';
import BrowserStore from './stores/BrowserStore';
import eventLogger from '../ClientEventLogger';
import BrowserLogin from '../Components/Authentication/BrowserLogin';
import BrowserLoginFailureSSO from '../Components/Authentication/BrowserLoginFailureSSO';
import BrowserLoginFailure from '../Components/Authentication/BrowserLoginFailure';

const render = (el, ctn) =>
    clientConfig.prepareConfig().then(() => originalRender(el, ctn));

const PAGE_TYPE = {
    LOGIN: 'login',
    BROWSER: 'browser',
    DIAGNOSTIC: 'diagnostic',
    ERROR: 'error',
};

if (window._W_DISABLED_INIT) {
    disableSentryNotifications();
}

const rootEl = document.getElementById('root');
ReactModal.setAppElement('#root');
const type = initData.type || PAGE_TYPE.BROWSER;
const sessionId = initData.stores.env.sessionId || '';
const vagueSessionId = sessionId ? sessionId.slice(0, 12) : 'no session id';
const timezone = window.Intl && window.Intl.DateTimeFormat &&
    window.Intl.DateTimeFormat().resolvedOptions && window.Intl.DateTimeFormat().resolvedOptions().timeZone;
const originalQuery = { ...browserHistory.getCurrentLocation().query };
initData.stores.history.clickId = initData.stores.history.clickId || originalQuery.clickId;

eventLogger.extendContext({
    flowId: uuid(),
    timezone,
    sessionId,
    browserId: initData.stores.env.browserId,
    policyId: initData.stores.env.policyId,
    policyName: initData.stores.env.policyName,
    customerId: initData.stores.env.customerId,
    productId: initData.stores.env.productId,
    cluster: initData.stores.env.cluster,
    mirrorRelease: initData.release,
    clickId: initData.stores.history ? initData.stores.history.clickId : null,
}, true);

if (eventLogger.isContextInitialized()) {
    eventLogger.logTimeElapsedSinceTheTimeOrigin(eventLogger.EVENT_BROWSER_BUNDLE_LOAD_BASIC);
}

window.addEventListener('load', () => {
    setTimeout(() => {
        if (eventLogger.isContextInitialized()) {
            eventLogger.logBrowserPageLoadingPerformance();
        }
        eventLogger.logClientPageLoadingRawData(type);
    }, 0);
});
Raven.setEnvironment(initData.env);
Raven.setTagsContext({
    bundle: 'browser',
    mirrorRelease: initData.release,
    browserViewType: type,
    customerId: initData.stores.env.customerId,
});
Raven.setUserContext({ sessionId });
browserHistory.listen((location) => {
    Raven.setExtraContext({ realURL: (location.query && location.query.url) || 'about:blank' });
    debug.addDebugAttribute(document, 'weblife-url', window.location.href);
});

debug.addDebugAttribute(document, 'weblife-session-id', vagueSessionId);

let stores;
if (type !== PAGE_TYPE.ERROR || type === PAGE_TYPE.BROWSER || type === PAGE_TYPE.DIAGNOSTIC) {
    // if Browser by mistake loaded recursive page(e.g. /page redirected to /login
    // replace parent location with current location
    try {
        if ((window.parent !== window) && (window.parent.location.host === window.location.host)) {
            window.parent.location.replace(window.location.href);
        }
    } catch (e) { /* whatever */ }

    LocalStorage.setRaw('latestOpenedURL', window.location.href);
    if (window.location.pathname.indexOf('/troubleshooting/') >= 0) {
        LocalStorage.set(LS_KEY.troubleshootingCaseId,
            window.location.pathname.split('/troubleshooting/')[1] || '');
    }
    const troubleshootingCaseId = LocalStorage.get(LS_KEY.troubleshootingCaseId, '');
    initData.stores.browser.troubleshootingMode = !!troubleshootingCaseId;
    initData.stores.browser.troubleshootingCaseId = troubleshootingCaseId;

    initData.stores.browser.originalQuery = originalQuery;
    initData.stores.passwordlessAuth.originalQuery = originalQuery;
    initData.stores.history.traceToken = initData.stores.history.traceToken || originalQuery.traceToken;
    if (Array.isArray(initData.stores.env.mirrorFeatureFlags)) {
        initData.stores.browser.mirrorFeatureFlags = new Set(initData.stores.env.mirrorFeatureFlags);
    }

    const previousSessionId = LocalStorage.get(LS_KEY.previousSessionId, '');
    LocalStorage.set(LS_KEY.previousSessionId, vagueSessionId);
    const isNewSession = previousSessionId !== vagueSessionId;

    if (initData.stores.env.isUrlIsolation) {
        // For old policies we pretty much know we always scan, so we can set this right from the start.
        // New policies need to process the URL first though, so we can not. We would set it in the index.client.js init
        if (!initData.stores.browser.useRedesignedPolicy) {
            initData.stores.browser.tsaScan = BrowserStore.SCAN_STATUS.SCANNING;
        }
        initData.stores.browser.showExternalBlockPageOnDanger = true;

        if (!isHuman()) {
            initData.stores.browser.notification = BrowserStore.NOTIFICATION.HUMAN_CHECK;
        }
    } else if (!initData.stores.env.isSaasIsolation) {
        initData.stores.browser.homePageTabId = isNewSession ? 1 : 0;
    }

    if (isNewSession) {
        LocalStorage.remove(LS_KEY.fileManagerBlockedFilesEvents);
    }

    if (!initData.stores.browser.settingsResearchMode && LocalStorage.get(LS_KEY.researchMode, true)) {
        LocalStorage.set(LS_KEY.researchMode, false);
    }

    if (initData.stores.browser.useFileThreatRules) {
        initData.stores.browser.isDownloadDisabled = false;
        initData.stores.browser.isUploadDisabled = false;
    }

    stores = initBrowserStores(initData.stores, true);

    if (isNewSession
        && stores.browser.enterIso?.enabled
        && stores.browser.mirrorFeatureFlags.has('CUSTOMIZE_UI')
    ) {
        stores.browser.showNotification(BrowserStore.NOTIFICATION.ENTER_ISOLATION_MESSAGE);
    }

    render(<I18nextProvider i18n={i18n}>
        <Provider {...stores}>
            <RootPage appName="browser">
                <Router history={browserHistory}>
                    <Route path="/login" component={BrowserLogin} />
                    <Route path="/browser" component={BrowserController} />
                    <Route path="/auth/browser/error" component={BrowserLoginFailure} />
                    <Route path="/sso/browser/error" component={BrowserLoginFailureSSO} />
                    <Route path="/troubleshooting/:caseId" component={BrowserController} />
                </Router>
            </RootPage>
        </Provider>
    </I18nextProvider>, rootEl);
} else if (type === PAGE_TYPE.ERROR) {
    stores = {
        env: new EnvStore(initData.stores.env),
    };

    if (DEBUG && ['prod', 'demo', 'staging'].indexOf(initData.env) === -1) {
        const debugEl = document.createElement('div');
        debugEl.setAttribute('style',
            'position: absolute; background: #fff; white-space: pre-wrap; font-family: monospace;');
        debugEl.innerHTML = JSON.stringify({
            env: initData.env,
            errorViewCode: initData.stores.errorViewCode,
            ...(initData.stores.errorDetail || {}),
        }, null, 4);
        document.body.appendChild(debugEl);
    }

    render(<I18nextProvider i18n={i18n}>
        <Provider {...stores}>
            <RootPage appName="browser">
                <BrowserErrorPage clickId={originalQuery.clickId} />
            </RootPage>
        </Provider>
    </I18nextProvider>, rootEl);
}

// cleanup noscript
((node) => {
    if (node && node.parentNode) {
        node.parentNode.removeChild(node);
    }
})(document.getElementById('noscript'));

// easy access to iframe's debug
try {
    Object.defineProperty(window, '_', {
        get: () => {
            const iframe = document.querySelector('iframe');
            if (iframe && iframe.contentWindow) {
                return iframe.contentWindow._;
            }
        },
    });
} catch (e) { /* whatever */ }

// expose state for debug
window.STATE = stores;
